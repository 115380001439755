import React, { useCallback, useState, useEffect } from 'react';
import { Box, Button, Stack, Typography, Skeleton, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Modal, Alert, colors } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDropzone } from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import colorPalette from '../theme/colorPalette';



interface ReceiptData {
    merchantName?: string;
    merchantAddress?: string;
    totalAmount?: number;
    items: Item[]; // Assuming you have an array of items
}

// Define an interface for individual items, if needed
interface Item {
    itemName: string;
    category: string;
    amount: number;
    notes?: string;
}

const ReceiptScanner = () => {
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<any[]>([]);
    const [completeData, setCompleteData] = useState<ReceiptData | null>(null);
    const [uuId, setUuId] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [error, setError] = useState('');

    const onDrop = useCallback((acceptedFiles: any) => {
        const fileToUpload = acceptedFiles[0];
        setFile(fileToUpload);
        uploadFile(fileToUpload);
    }, []);

    useEffect(() => {
        // Check for existing UUID in local storage
        let storedUuid = localStorage.getItem('coco-uuid');
        if (!storedUuid) {
            // Generate a new UUID and store it if not found
            storedUuid = uuidv4();
            localStorage.setItem('coco-uuid', storedUuid);
        }
        setUuId(storedUuid);
    }, []);


    const handleImageClick = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleDelete = (indexToDelete: number) => {
        const newData = data.filter((_, index) => index !== indexToDelete);
        setData(newData);
    };

    const reset = () => {
        setFile(null);
        setError('');
        setData([]);
        setLoading(false);
    };

    const convertFileToDataURL = (file: File): Promise<{ name: string; url: string }> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                resolve({ name: file.name, url: reader.result as string });
            };
            reader.onerror = reject;
        });
    };


    const uploadFile = async (file: File) => {
        setLoading(true);
        setError('');
        try {
            // Convert the file to a Data URL
            const convertedFile = await convertFileToDataURL(file);

            // Prepare the form data
            const formData = new FormData();
            formData.append('uuId', uuId);
            
            formData.append('imgUrl', convertedFile.url);

            const serverUrl = process.env.REACT_APP_SERVER_URL;
            // Make the POST request
            const response = await axios.post(`${serverUrl}/api/otp/addreceipttrial`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            const parsedResponse = JSON.parse(response.data);
            setData(parsedResponse.items);
            setCompleteData(parsedResponse);
            console.log('parsedResponse.items', parsedResponse.items);
        } catch (error) {
            setError('An error occurred while processing the file. Please try again.');
            console.error('Error uploading file:', error);
        } finally {
            setLoading(false);
        }


    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const boxStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        width: '100%',
        maxWidth: '480px',
        borderWidth: '2px',
        borderRadius: '5px',
        borderColor: '#ccc',
        borderStyle: 'dashed',
        backgroundColor: isDragActive ? '#f0f0f0' : '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out',
        cursor: 'pointer',
    };

    return (
        <Stack mb={8} gap={2}>
            {error && (
                <Alert severity="error" sx={{ mb: 2, justifyContent: 'center', alignItems: '' }}>
                    {error}
                    <Button variant="outlined" color="secondary" onClick={reset}>
                        Reset
                    </Button>
                </Alert>
            )}



            {!file && !error &&
                <Stack direction="column" width='100%' alignItems="center" spacing={2}>

                    <Typography sx={{ fontWeight: '800', background: colorPalette.Purple_600, p: '2px 10px', borderRadius: '20px', color: colorPalette.white }} variant="subtitle1">Try it now</Typography>
                    <Stack {...getRootProps()} sx={boxStyle}>
                        <input {...getInputProps()} />
                        <Typography variant="body1">
                            {isDragActive ? 'Drop the files here...' : 'Drag grocery receipt image or browse from computer'}
                        </Typography>
                    </Stack>
                    <Button
                        variant="contained"
                        onClick={() => (document.querySelector('input[type="file"]') as HTMLInputElement)?.click()}
                    >
                        Browse
                    </Button>
                </Stack>
            }
            {file && !error &&
                <Stack direction="column" spacing={2} alignItems="center" justifyContent="center" sx={{ maxWidth: '100%', mt: 4 }}>
                    <Box p={2} borderRadius={'10px'} sx={{ background: colorPalette.Light_Green_100 }}>
                        {file && <img src={URL.createObjectURL(file)} alt="Receipt" style={{ height: '120px', width: '120px', objectFit: "cover", cursor: 'pointer', borderRadius: '4px' }} onClick={handleImageClick} />}

                        {/* Modal for enlarged image */}
                        <Modal open={modalOpen} onClose={handleCloseModal}>
                            <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
                                {file && <img src={URL.createObjectURL(file)} alt="Receipt" style={{ maxWidth: '100%', maxHeight: '90vh' }} />}
                            </Box>
                        </Modal>

                        {/* Merchant Information */}
                        {data.length > 0 && (
                            <Stack sx={{ mb: 2, alignItems: 'center' }}>
                                {completeData?.merchantName && <Typography variant="h2">{completeData?.merchantName}</Typography>}
                                {completeData?.merchantAddress && <Typography variant="subtitle1">{completeData?.merchantAddress}</Typography>}
                                <Box p={'4px 12px'} borderRadius={'20px'} sx={{ background: colorPalette.Amber_800 }}>
                                    {completeData?.totalAmount && <Typography variant="h4" color={colorPalette.white}>Total Amount: {completeData?.totalAmount}</Typography>}
                                </Box>
                            </Stack>
                        )}
                    </Box>

                    {file && (
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }}>
                                {(!loading && data.length > 0) && (
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Item Name</TableCell>
                                            <TableCell>Category</TableCell>
                                            <TableCell>Amount</TableCell>
                                            <TableCell>Complete Line</TableCell>
                                        </TableRow>
                                    </TableHead>
                                )}
                                <TableBody>
                                    {loading ? (
                                        [...Array(4)].map((_, index) => (
                                            <TableRow key={index}>
                                                <TableCell><Skeleton /></TableCell>
                                                <TableCell><Skeleton /></TableCell>
                                                <TableCell><Skeleton /></TableCell>
                                                <TableCell><Skeleton /></TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        data.map((item, index) => (
                                            <TableRow key={index} sx={{
                                                '&:hover': {
                                                    backgroundColor: colorPalette.Purple_600,
                                                    color: 'white',
                                                    cursor: 'pointer',
                                                },
                                                '&:hover .deleteButton': {
                                                    opacity: 1,
                                                    color: 'white',

                                                },
                                                '&:hover .texto': {
                                                    opacity: 1,
                                                    color: 'white',

                                                },
                                            }}>
                                                <TableCell><Typography className='texto' variant='body1'>{item?.itemName}</Typography></TableCell>
                                                <TableCell><Typography className='texto' variant='body2'>{item?.category}</Typography></TableCell>
                                                <TableCell><Typography className='texto' variant='body1'>{item?.amount}</Typography></TableCell>
                                                <TableCell><Typography className='texto' variant='body2'>{item?.notes}</Typography></TableCell>
                                                <TableCell align="right">
                                                    <IconButton
                                                        className="deleteButton"
                                                        sx={{ opacity: 0 }}
                                                        onClick={() => handleDelete(index)}
                                                        color="inherit"
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </Stack>
            }
            {
                file && !error && (
                    <Stack direction="row" justifyContent={'center'} spacing={2} sx={{ mt: 2 }}>
                        <Button variant="outlined" color="secondary" onClick={reset}>
                            Reset
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => (document.querySelector('input[type="file"]') as HTMLInputElement)?.click()}
                        >
                            Upload New File
                        </Button>
                    </Stack>
                )
            }
        </Stack >
    );
};

export default ReceiptScanner;
