const colorPalette = {
    //Primary Colors    
    black: '#000000',
    white: '#FFFFFF',
    primaryColor: '3B5072',
    //Shades of Gray
    gray_900: '#212121',
    gray_800: '#424242',
    gray_700: '#616161',
    gray_600: '#757575',
    gray_500: '#9E9E9E',
    gray_400: '#BDBDBD',
    gray_300: '#E0E0E0',
    gray_200: '#EEEEEE',
    gray_100: '#F5F5F5',
    gray_050: '#FAFAFA',
    //Shades of Blue Gray
    blue_gray_900: '#283238',
    blue_gray_800: '#3A474E',
    blue_gray_700: '#495A63',
    blue_gray_600: '#596E79',
    blue_gray_500: '#667D8A',
    blue_gray_400: '#7D909B',
    blue_gray_300: '#94A4AD',
    blue_gray_200: '#B3BEC4',
    blue_gray_100: '#D1D8DC',
    blue_gray_050: '#EDEFF1',
    //Shades of Brown
    brown_900: '#3B2824',
    brown_800: '#4A352F',
    brown_700: '#594139',
    brown_600: '#684D43',
    brown_500: '#74574A',
    brown_400: '#886F65',
    brown_300: '#9D8981',
    brown_200: '#B9ABA5',
    brown_100: '#D5CDC9',
    brown_050: '#EFEBE9',
    //Shades of Deep Orange
    deep_orange_A700: '#CC3E20',
    deep_orange_A400: '#EB4F27',
    deep_orange_A200: '#EE764E',
    deep_orange_A100: '#F2A286',
    deep_orange_900: '#B14121',
    deep_orange_800: '#C84E2A',
    deep_orange_700: '#D5552E',
    deep_orange_600: '#E25D33',
    deep_orange_500: '#ED6337',
    deep_orange_400: '#EE7850',
    deep_orange_300: '#F0906E',
    deep_orange_200: '#F3AF96',
    deep_orange_100: '#F7CEBF',
    deep_orange_050: '#FBEAE5',
    //Shades of Deep Orange
    Orange_A700: '#F8EAE8',
    Orange_A400: '#EE752F',
    Orange_A200: '#F19637',
    Orange_A100: '#F3AE56',
    Orange_900: '#D55B26',
    Orange_800: '#D55B26',
    Orange_700: '#DF732D',
    Orange_600: '#E68231',
    Orange_500: '#ED9135',
    Orange_400: '#F19C38',
    Orange_300: '#F3AA47',
    Orange_200: '#F5B961',
    Orange_100: '#F7CD8B',
    Orange_050: '#FAE1B8',
    //Shades of Amber
    Amber_A700: '#FDF3E2',
    Amber_A400: '#F3AE3D',
    Amber_A200: '#F6C544',
    Amber_A100: '#F9D85F',
    Amber_900: '#FBE58D',
    Amber_800: '#EE772F',
    Amber_700: '#F09436',
    Amber_600: '#F2A43A',
    Amber_500: '#F4B53F',
    Amber_400: '#F6C344',
    Amber_300: '#F7CB50',
    Amber_200: '#F9D668',
    Amber_100: '#FAE08F',
    Amber_050: '#FCECBA',
    //Shades of Yellow
    Yellow_A700: '#FEF8E4',
    Yellow_A400: '#F9D749',
    Yellow_A200: '#FCEA4F',
    Yellow_A100: '#FFFE55',
    Yellow_900: '#FFFE9C',
    Yellow_800: '#E68538',
    Yellow_700: '#EEAB46',
    Yellow_600: '#F2C24F',
    Yellow_500: '#F7D859',
    Yellow_400: '#FCEB60',
    Yellow_300: '#FDEE72',
    Yellow_200: '#FDF188',
    Yellow_100: '#FEF5A8',
    Yellow_050: '#FEF9CA',
    //Shades of Lime
    Lime_A700: '#FFFDE9',
    Lime_A400: '#BBE749',
    Lime_A200: '#D2FC51',
    Lime_A100: '#F2FD67',
    Lime_900: '#F6FE92',
    Lime_800: '#80772C',
    Lime_700: '#9E9C3D',
    Lime_600: '#B0B347',
    Lime_500: '#C2C951',
    Lime_400: '#D0DB59',
    Lime_300: '#D7E06D',
    Lime_200: '#DEE685',
    Lime_100: '#E8EDA6',
    Lime_050: '#F1F4C8',
    //Shades of Light Green
    Light_Green_A700: '#FAFBE9',
    Light_Green_A400: '#85D947',
    Light_Green_A200: '#9BFB4E',
    Light_Green_A100: '#C3FC73',
    Light_Green_900: '#D7FD9D',
    Light_Green_800: '#41672A',
    Light_Green_700: '#62893D',
    Light_Green_600: '#759D48',
    Light_Green_500: '#88B153',
    Light_Green_400: '#97C15C',
    Light_Green_300: '#A6CA72',
    Light_Green_200: '#B6D38B',
    Light_Green_100: '#CBE0AB',
    Light_Green_050: '#DFECCC',
    //Shades of Green
    Green_A700: '#F3F8EB',
    Green_A400: '#5AC462',
    Green_A200: '#69E282',
    Green_A100: '#8EEDB3',
    Green_900: '#C6F4CE',
    Green_800: '#305C28',
    Green_700: '#457B3C',
    Green_600: '#508C46',
    Green_500: '#5D9D52',
    Green_400: '#67AC5C',
    Green_300: '#7BB873',
    Green_200: '#91C58A',
    Green_100: '#AFD4AB',
    Green_050: '#CEE5CC',
    //Shades of Cyan
    Cyan_A700: '#E4F2F1',
    Cyan_A400: '#52B6D1',
    Cyan_A200: '#68E2FC',
    Cyan_A100: '#77FCFD',
    Cyan_900: '#A3FCFE',
    Cyan_800: '#275F63',
    Cyan_700: '#38818D',
    Cyan_600: '#4295A5',
    Cyan_500: '#4CAABE',
    Cyan_400: '#54BAD1',
    Cyan_300: '#5FC4D7',
    Cyan_200: '#73CEDE',
    Cyan_100: '#96DCE8',
    Cyan_050: '#BEEAF1',
    //Shades of Light Blue
    Light_Blue_A700: '#E4F7FA',
    Light_Blue_A400: '#3F91E3',
    Light_Blue_A200: '#4EAFF9',
    Light_Blue_A100: '#68C2FA',
    Light_Blue_900: '#95D7FB',
    Light_Blue_800: '#235796',
    Light_Blue_700: '#3377B8',
    Light_Blue_600: '#3B87CB',
    Light_Blue_500: '#449ADF',
    Light_Blue_400: '#4BA8EE',
    Light_Blue_300: '#59B5F0',
    Light_Blue_200: '#6FC2F2',
    Light_Blue_100: '#94D3F6',
    Light_Blue_050: '#BDE4FA',
    //Shades of Blue
    Blue_A700: '#E5F5FD',
    Blue_A400: '#3765F6',
    Blue_A200: '#407BF7',
    Blue_A100: '#558BF7',
    Blue_900: '#8CB1F9',
    Blue_800: '#1F489C',
    Blue_700: '#2F66BA',
    Blue_600: '#3876CC',
    Blue_500: '#4188DE',
    Blue_400: '#4896EC',
    Blue_300: '#5DA4EF',
    Blue_200: '#78B4F1',
    Blue_100: '#9CC9F5',
    Blue_050: '#C2DEF8',
    //Shades of Indigo
    Indigo_A700: '#E6F2FC',
    Indigo_A400: '#3654F5',
    Indigo_A200: '#425EF5',
    Indigo_A100: '#5870F5',
    Indigo_900: '#8F9FF8',
    Indigo_800: '#1B2679',
    Indigo_700: '#2A378E',
    Indigo_600: '#33419A',
    Indigo_500: '#3C4BA5',
    Indigo_400: '#4253AF',
    Indigo_300: '#5F6CBA',
    Indigo_200: '#7B87C6',
    Indigo_100: '#A1A9D6',
    Indigo_050: '#C6CAE7',
    //Shades of Deep Purple
    Deep_Purple_A700: '#E9EAF5',
    Deep_Purple_A400: '#5920E1',
    Deep_Purple_A200: '#5D30F5',
    Deep_Purple_A100: '#7555F6',
    Deep_Purple_900: '#AC8CF8',
    Deep_Purple_800: '#2E218C',
    Deep_Purple_700: '#412D9A',
    Deep_Purple_600: '#4C33A2',
    Deep_Purple_500: '#583BAB',
    Deep_Purple_400: '#6140B1',
    Deep_Purple_300: '#785BBC',
    Deep_Purple_200: '#9078C8',
    Deep_Purple_100: '#AF9FD7',
    Deep_Purple_050: '#CFC5E6',
    //Shades of Purple
    Purple_A700: '#ECE8F5',
    Purple_A400: '#9C2CF6',
    Purple_A200: '#C333F1',
    Purple_A100: '#CF53F3',
    Purple_900: '#DC88F6',
    Purple_800: '#441D87',
    Purple_700: '#612695',
    Purple_600: '#712B9C',
    Purple_500: '#8331A4',
    Purple_400: '#9035AA',
    Purple_300: '#9F50B7',
    Purple_200: '#AF6EC3',
    Purple_100: '#C597D4',
    Purple_050: '#DCC0E4',
    //Shades of Pink
    Pink_A700: '#F1E6F4',
    Pink_A400: '#B52C62',
    Pink_A200: '#E1315A',
    Pink_A100: '#EC5282',
    Pink_900: '#EF88AB',
    Pink_800: '#7D1E4E',
    Pink_700: '#9F2857',
    Pink_600: '#B22E5B',
    Pink_500: '#C73461',
    Pink_400: '#D63965',
    Pink_300: '#DA4F7A',
    Pink_200: '#DF6C92',
    Pink_100: '#E794B1',
    Pink_050: '#EFBED0',
    //Shades of Red
    Red_A700: '#F8E5EC',
    Red_A400: '#C4291C',
    Red_A200: '#EB3A4B',
    Red_A100: '#EC5F59',
    Red_900: '#F09085',
    Red_800: '#A82E26',
    Red_700: '#B63831',
    Red_600: '#C33F38',
    Red_500: '#D3483F',
    Red_400: '#E25241',
    Red_300: '#DE5E57',
    Red_200: '#D77976',
    Red_100: '#E39E9C',
    Red_050: '#F7CFD3',
};

export default colorPalette;